<template>
  <component :is="tag" :class="['aspectratio', `ratio-${ratio}`]">
    <span>
      <slot/>
    </span>
  </component>
</template>

<script>
export default {
  name: 'AspectRatio',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    ratio: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.aspectratio {
  display: block;
  position: relative;
  width: 100%;

  &:before {
    display: block;
    content: '';
  }

  @each $key, $value in $aspect-ratios {
    &.ratio-#{$key}:before {
      padding-top: 100% * calc(1 / $value);
    }
  }

  > span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
